import {makeAutoObservable} from "mobx";
import {
    api,
    catchApi,
    SetUserOrganizationGroupEnum,
    UserDto,
    UserOrganizationGroupDtoOrganizationRoleEnum
} from "../api";

export interface UserOption {
    value: string,
    label: string,
}
class OrganizationUserGroupStore {
    get userGroup(): Record<string, UserOrganizationGroupDtoOrganizationRoleEnum> {
        return this._userGroup;
    }

    set userGroup(value: Record<string, UserOrganizationGroupDtoOrganizationRoleEnum>) {
        this._userGroup = value;
    }
    get userRole(): SetUserOrganizationGroupEnum {
        return this._userRole;
    }

    set userRole(value: SetUserOrganizationGroupEnum) {
        this._userRole = value;
    }


    get userMapping(): UserOption | undefined {
        return this._userMapping;
    }

    set userMapping(value: UserOption | undefined) {
        this._userMapping = value;
    }

    get users(): UserDto[] {
        return this._users;
    }

    set users(value: UserDto[]) {
        this._users = value;
    }


    get organizationId(): string | undefined {
        return this._organizationId;
    }

    set organizationId(value: string | undefined) {
        this._organizationId = value;
    }

    constructor() {
        makeAutoObservable(this)
    }

    private _userGroup: Record<string, UserOrganizationGroupDtoOrganizationRoleEnum> = {}
    private _users: UserDto[] = []
    private _organizationId: string | undefined
    private _userMapping?: UserOption
    private _userRole: SetUserOrganizationGroupEnum = SetUserOrganizationGroupEnum.USER

    async load() {
        await catchApi(async () => {
            if (this.organizationId) {
                const userGroupResponse = await api.v1.listTenantOrganizationGroups(this.organizationId)
                this.userGroup = userGroupResponse.data.organizationRole
                const userResponse = await api.v1.getUsersByOrganization(this.organizationId)
                this.users = userResponse.data.items
            }
        })
    }

    async searchUserOptions(query: string) {
        return await catchApi(async () => {
            const userSearchResponse = await api.v1.listUser({search: query, size: 20})
            return userSearchResponse.data.items.map(it => ({label: it.username, value: it.id}))
        }) || []
    }

    async createUserMapping() {
        await catchApi(async () => {
            if(this.userMapping && this.userMapping.value && this.userRole && this.organizationId) {
                await api.v1.setUserOrganizationGroup(
                    this.userMapping.value,
                    this.organizationId,
                    this.userRole
                )
            }
        })
    }

    reset() {
        this.userMapping = undefined
    }

    async removeUserFromOrganization(userId: string, organizationId: string) {
        await catchApi(async () => {
            await api.v1.deleteUserOrganizationGroup(userId, organizationId)
        })
    }
}

const organizationUserGroupStore = new OrganizationUserGroupStore()

export {
    organizationUserGroupStore
}
