import {observer} from "mobx-react";
import {growlStore, organizationUserGroupStore} from "../../../store";
import {ScrollTable} from "../../ui";
import {useNavigate} from "react-router-dom";
import {organizationRoleName} from "../../../api";

const OrganizationUserGroupList = observer(function OrganizationUserGroupList() {
    const navigate = useNavigate()
    return <div style={{display: "flex", flex: "1", marginTop: "-1.5em"}}>
        <ScrollTable>
            <thead>
            <tr>
                <th>Benutzername</th>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>Rolle</th>
                <th style={{width: '8em'}}></th>
            </tr>
            </thead>
            <tbody className={"user-table"}>
            {organizationUserGroupStore.users.map(user => {
                return <tr key={user.id} className={`${user.active?'':'disabled'}`}>
                    <td><a href={`/organization/user/${user.id}`} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(`/organization/user/${user.id}`)
                    }}>{user.username}</a></td>

                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{organizationRoleName(organizationUserGroupStore.userGroup[user.id])}</td>
                    <td style={{textAlign: 'end'}}><button onClick={async () => {
                        const organizationId = organizationUserGroupStore.organizationId
                        if (organizationId) {
                            await organizationUserGroupStore.removeUserFromOrganization(user.id, organizationId)
                            await organizationUserGroupStore.load()
                            growlStore.addMessage({
                                message: `Benutzer aus Standort entfernt`,
                                type: 'success',
                                autoHide: true
                            })
                        }
                    }} className={"button is-small is-primary"}>Entfernen</button></td>

                </tr>
            })}
            </tbody>
        </ScrollTable>
    </div>
})

export {
    OrganizationUserGroupList
}
